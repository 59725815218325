.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.bold{
    display: inline;
    color:rgb(255, 0, 132)
}
.title{
    font-size:3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    color:rgb(255, 255, 255);
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    direction:rtl
}

.description{
    font-size:1.5rem;
    color:rgb(255, 255, 255);
    font-family: "AssistantR";
    text-align: center;
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    padding-top:2%;

    margin:0 auto;
    direction: rtl;
    width:40%;
}
.center{
    display: flex;
    margin:2% auto;
    justify-content: center;
}
@media only screen and (max-width: 350px) {


    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .title{
        font-size:2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.1rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:2% auto;
        direction: rtl;
        width:95%;
    }
    .center{
        display: flex;
        margin:2% auto;
        justify-content: center;
    }

}
@media only screen  and (min-width: 350px) and (max-width: 450px) {


    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .title{
        font-size:2.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.2rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:2% auto;
        direction: rtl;
        width:95%;
    }
    .center{
        display: flex;
        margin:2% auto;
        justify-content: center;
    }

}
@media only screen  and (min-width: 450px) and (max-width: 550px) {

    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .title{
        font-size:2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.2rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:2% auto;
        direction: rtl;
        width:95%;
    }
    .center{
        display: flex;
        margin:2% auto;
        justify-content: center;
    }

}
@media only screen  and (min-width: 550px) and (max-width: 650px) {


    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .title{
        font-size:2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.25rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:2% auto;
        direction: rtl;
        width:85%;
    }
    .center{
        display: flex;
        margin:2% auto;
        justify-content: center;
    }
}
@media only screen  and (min-width: 650px) and (max-width: 850px) {


    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .title{
        font-size:3rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.25rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:2% auto;
        direction: rtl;
        width:85%;
    }
    .center{
        display: flex;
        margin:2% auto;
        justify-content: center;
    }
}
@media only screen  and (min-width: 850px) and (max-width: 1050px) {


    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .title{
        font-size:3rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.35rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:2% auto;
        direction: rtl;
        width:75%;
    }
    .center{
        display: flex;
        margin:2% auto;
        justify-content: center;
    }
}