.button {
    position: relative;
    margin: 0;
    padding: 0.8em 1em;
    outline: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    background-color: #333333;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 0 5px 1px rgb(255, 0, 132);
    font-size: 18px;
    font-family: "Assistant";
    z-index: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  

  
  
  
  .button:hover .span {
    animation: storm 0.7s ease-in-out both;
    animation-delay: 0.06s;
  }
  
  .button::before,
  .button::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
    z-index: -1;
    transform: translate(100%, -25%) translate3d(0, 0, 0);
  }
  
  .button:hover::before,
  .button:hover::after {
    opacity: 0.15;
    transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  
  .button:hover::before {
    transform: translate3d(50%, 0, 0) scale(0.9);
  }
  
  button:hover::after {
    transform: translate(50%, 0) scale(1.1);
  }
  
  