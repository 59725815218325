.title{
    font-size:3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    color:rgb(255, 255, 255);
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    direction:rtl
}
.bold{
    display: inline;
    color:rgb(255, 0, 132)
}

.description{
    font-size:1.5rem;
    color:rgb(255, 255, 255);
    font-family: "AssistantR";
    text-align: center;
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    padding-top:2%;

    margin:0 auto;
    direction: rtl;
    width:40%;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.center{
    display: flex;
    margin:0 auto;
    width:35%;
    justify-content: center;
}

.image{
    display: flex;
width:100%;
object-fit: contain;

}
.background{
    /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
}
.backgroundP{
    padding-top: 80px;
}

@media only screen and (max-width: 350px){
    .title{
        font-size:2.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.1rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:90%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:90%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px){
    .title{
        font-size:2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:95%;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.15rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:90%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:85%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px){
    .title{
        font-size:2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:95%;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.2rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:90%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:75%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px){
    .title{
        font-size:3rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:95%;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.25rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:90%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:65%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}
@media only screen and (min-width: 650px) and (max-width: 750px){
    .title{
        font-size:3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:95%;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.25rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:80%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:55%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}
@media only screen and (min-width: 750px) and (max-width: 850px){
    .title{
        font-size:3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        width:95%;
        color:rgb(255, 255, 255);
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        direction:rtl
    }
    
    .description{
        font-size:1.35rem;
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:90%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:55%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}
@media only screen and (min-width:850px) and (max-width: 1050px){
   
    .description{
     
        color:rgb(255, 255, 255);
        font-family: "AssistantR";
        text-align: center;
        text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
        padding-top:2%;
    
        margin:0 auto;
        direction: rtl;
        width:90%;
    }
    
    .center{
        display: flex;
        margin:0 auto;
        width:50%;
        justify-content: center;
    }
    
    .image{
        display: flex;
    width:100%;
    object-fit: contain;
    
    }
    .background{
        /* background: linear-gradient(150deg, rgba(15,15,15,1) 0%, rgba(15,15,15,0.8543066884957108) 10%, rgba(15,15,15,1) 20%, rgba(15,15,15,0.8515055680475315) 30%, rgba(15,15,15,1) 40%, rgba(15,15,15,0.8515055680475315) 50%, rgba(15,15,15,1) 60%, rgba(15,15,15,0.8487044475993523) 70%, rgba(15,15,15,1) 80%, rgba(15,15,15,0.8487044475993523) 90%, rgba(15,15,15,1) 100%); */
    }
    .backgroundP{
        padding-top: 80px;
    }
}